/* globals window */

'use strict';

export default class Accordion {

  constructor() {
    this.accordions = [];
    this.activeClass = 'is-active';

    document.querySelectorAll(`[data-accordion]`).forEach(el => {
      const accordionData = {
        el,
        id: el.dataset.accordion,
        items: [],
      };
      el.querySelectorAll(`[data-accordion-item]`).forEach(itemEl => {
        const hasToggle = itemEl.querySelector(`[data-accordion-item-toggle]`);
        if (hasToggle) {
          accordionData.items.push({
            el: itemEl,
            toggleTriggerEl: hasToggle,
            active: false
          });
        }

      });
      this.accordions.push(accordionData);
    });

    if (this.accordions.length) {
      this.bindEvents();
    }
  }

  bindEvents() {
    this.accordions.forEach(accordion => accordion.items.forEach(item => {
      if (item.toggleTriggerEl) {
        item.toggleTriggerEl.addEventListener('click', () => {
            this.toggle(item);
        });
      }
    }));
  }

  open(item) {
    item.el.querySelector('[data-accordion-item-toggle]').disabled = true;

    const tray = item.el.querySelector('[data-accordion-item-tray]');
    let trayHeight = ''; // tray.dataset('accordion-item-tray');

    if (!tray.dataset.accordionItemTray) {
        tray.dataset.accordionItemTray = this.getContentHeight(item) + 'px';
    }
    
    item.el.querySelector('[data-accordion-item-tray]').style.maxHeight =  tray.dataset.accordionItemTray;
    item.el.classList.add(this.activeClass, 'animate-in');
 
    window.setTimeout(() => {
        item.el.querySelector('[data-accordion-item-toggle]').disabled = false;
    }, 500);
    
  }

  close(item) {
    item.el.querySelector('[data-accordion-item-toggle]').disabled = true;
    item.el.classList.add('animate-out');
    item.el.querySelector('[data-accordion-item-tray]').style.maxHeight =  0;
    item.el.classList.remove(this.activeClass, 'animate-in', 'animate-out');

    window.setTimeout(() => {
        item.el.querySelector('[data-accordion-item-toggle]').disabled = false;
    }, 500);
  }

  toggle(item) {
    if (item.el.classList.contains(this.activeClass) && item.active) {
      this.close(item);
      item.active = false;
    } else {
      this.open(item);
      item.active = true;
    }

  }

  getContentHeight(item) {
    const itemContent = item.el.querySelector('[data-accordion-item-content]');
    return itemContent.offsetHeight || null;
  }
}
