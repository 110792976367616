'use strict';

import Accordion from "./modules/accordion";

class Main {

  constructor() {}

  bootstrap() {
    this.init();
  }

  init() {
    new Accordion();
  }
}

document.addEventListener('DOMContentLoaded', () => new Main().bootstrap());